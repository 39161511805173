export const defaultImagePaths = {
    CURRENCY_LOGO: 'assets/missions/generic-coin.svg',
};

// there is same reference in NotificationContext.vue file,
// if you change this line change reference in NotificationContext.vue as well
export const LAPTOP_WIDTH_PX = 1024;

export const DEFAULT_SIDEBAR_GUILDS_COUNT = 10;

export const VERIFY_GUILD_LINK = 'https://forms.gle/ebTUxga3w93QFn8v8';
export const TELEGRAM_GENERAL_LINK = 'https://t.me/bitd3gree';
export const BINANCE_JOIN_LINK = 'https://www.bitdegree.org/crypto/goon/binance';
export const KRAKEN_JOIN_LINK = 'https://www.bitdegree.org/crypto/goon/kraken';
